.root {
  background: #dddeff;
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
}

.left {
  display: flex;
  align-items: center;
  margin-top: -4px;
}

.logo {
  background: url('/img/logo.png');
  background-size: cover;
  height: 70px;
  width: 89px;
}

.title {
  font-size: 24px;
  margin-left: 12px;
}

@media(max-width: 480px) {
  .root {
    padding: 4px;
  }

  .title {
    font-size: 18px;
  }

  .logo {
    margin-left: -12px;
    height: 62px;
    width: 80px;
  }
}