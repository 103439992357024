.root {
  padding: 0 16px;

  :global(.ant-picker-calendar-header) {
    padding: 8px 0;
  }
}

.modalBody {
  border-top: 1px solid #d7d7d7;
  padding: 16px 0 8px;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.cellList {
  li>span {
    margin-top: 12px;
    width: calc(100% - 16px);
  }

  &.cellListSmall {
    li {
      line-height: 14px;
    }
  }
}

.cellTextItem {
  margin-right: 8px;
  margin-top: 10px;
}

.cellTextItemSmall {
  font-size: 11px;
  margin-right: 24px;
  margin-top: -6px;
}

.modalTextItem {
  margin-right: 2px;
  margin-top: 10px;
}

.modalList {
  font-size: 20px !important;

  >li {
    margin: 2px 0 26px;
    font-size: 16px;

    >span {
      font-size: 16px;
    }
  }
}

.edit, .remove {
  color: #454545;
  margin-left: 8px;
}

.edit {
  &:hover {
    color: #faad14
  }
}

.remove {
  font-size: 18px !important;
  margin-left: 22px;

  &:hover {
    color: #ff4d4f
  }
}

.inputRow {
  display: flex;
  align-items: center;
  margin: 8px 0 16px;
}

.inputRowLabel {
  font-weight: bolder;
}

.timeOffRow {
  display: flex;
  align-items: center;
  margin: 0 0 16px;
}

.inputTime {
  margin:  0 8px;
  width: 50px;
}

.alert {
  margin-top: 20px;
}

.supervisorSwitch {
  align-items: center;
  display: none;
  position: absolute;
  padding: 4px 0 0;
  height: 40px;

  &.active {
    display: flex;
  }
}

.selectUser {
  margin-left: 10px;
  width: 200px;
}

.legends {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.legend {
  align-items: center;
  display: flex;
  font-size: 17px;

  > div {
    align-items: center;
    display: flex;
  }

  > span {
    margin-right: 6px;
  }
}

.userSwitch {
  margin-left: 10px;
}
