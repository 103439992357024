.root {
  display: flex;
  align-items: center;
  justify-content: right;
}

.avatar {
  cursor: pointer;

  img {
    border-radius: 50%;
    height: 66px;
    width: 66px;
  }
}

.name {
  margin-right: 20px;
}

.email {
  font-size: 12px;
  margin-top: 2px;
}

@media(max-width: 480px) {
  .avatar {
    img {
      height: 50px;
      width: 50px;
    }
  }

}
